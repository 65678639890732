import UpWorkSVG from "../icons/UpWorkSVG";
import IndeedSVG from "../icons/IndeedSVG";
import LinkedinSVG from "../icons/LinkedinSVG";
import FreeLancerSVG from "../icons/FreeLancerSVG";
import ThreeJSSVG from "../icons/ThreeJSSVG";
import React from "react";

interface IconRendererProps {
    value: string;
    isSelected: boolean;
}

const IconRenderer : React.FC<IconRendererProps> = ({value, isSelected}) =>  {
    switch (value) {
        case 'upwork':
            return <UpWorkSVG width="60px" height="60px" color={isSelected? "#FEC400": "white"} />;
        case 'indeed':
            return <IndeedSVG color={isSelected? "#FEC400": "white"} />;
        case 'linkedin':
            return <LinkedinSVG color={isSelected? "#FEC400": "white"} />;
        case 'freelancer':
            return <FreeLancerSVG color={isSelected? "#FEC400": "white"} />;
        case 'threeJS':
            return <ThreeJSSVG color={isSelected? "#FEC400": "white"} />;
        default:
            return null;
    }
};

export default IconRenderer;