import React from "react";

interface SearchSVGProps {
    color?: string;
    width?: number | string;
    height?: number | string;
}
const SearchSVG : React.FC<SearchSVGProps> = ({ color, width, height }) =>{
    return (
        <>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7607 0C14.5805 0.110669 15.3977 0.237148 16.1938 0.469027C17.9732 0.988118 19.5628 1.85503 20.9573 3.06975C23.032 4.87208 24.4028 7.10918 24.9801 9.80213C25.5469 12.4371 25.2674 14.993 24.1576 17.4515C23.6699 18.5345 23.0372 19.5252 22.2569 20.4211C22.0012 20.7136 22.0039 20.7189 22.2859 20.9982C24.7191 23.3934 27.1523 25.7859 29.5881 28.1785C29.8095 28.3946 29.9914 28.6238 29.9993 28.9558C30.0125 29.3695 29.8411 29.6778 29.4852 29.8701C29.1294 30.0625 28.7682 30.0388 28.4334 29.8148C28.3043 29.7279 28.1909 29.6172 28.0802 29.5091C26.0451 27.5039 24.01 25.4961 21.9722 23.4909C21.5689 23.093 21.155 22.7083 20.7596 22.3025C20.5935 22.1338 20.4723 22.1365 20.2904 22.2761C19.0514 23.2194 17.6806 23.9045 16.1833 24.3367C14.6227 24.7899 13.0304 24.9296 11.4119 24.7873C9.29767 24.6002 7.34693 23.9335 5.59389 22.7531C2.67305 20.7821 0.81984 18.0733 0.160805 14.5873C0.0949011 14.2342 0.0527228 13.8758 0 13.5201C0 12.7797 0 12.0366 0 11.2962C0.100173 10.5742 0.202983 9.85219 0.400694 9.14865C1.05446 6.82987 2.27763 4.86681 4.06757 3.25157C5.55435 1.91036 7.2573 0.967038 9.18695 0.426867C9.9198 0.221338 10.6685 0.115939 11.4171 0H13.758H13.7607ZM1.98238 12.3818C2.00347 12.9351 2.02456 13.4779 2.11682 14.0207C2.64141 17.1116 4.23892 19.5015 6.85924 21.1879C9.05778 22.6002 11.483 23.0982 14.0744 22.7531C16.4363 22.4395 18.4873 21.4487 20.1559 19.7597C22.5917 17.2934 23.6014 14.3132 23.1111 10.8667C22.7314 8.19743 21.4424 6.01303 19.3546 4.33191C17.1692 2.57174 14.649 1.79706 11.8336 1.99205C10.1175 2.11062 8.51474 2.59019 7.05959 3.50189C3.78286 5.5519 2.11682 8.5347 1.97974 12.3818H1.98238Z" fill={color}/>
            </svg>

        </>
    );
}
export default SearchSVG;
