import React from "react";

interface FreeLancerSVGProps {
    color: string;
    width?: number | string;
    height?: number | string;
}
const FreeLancerSVG : React.FC<FreeLancerSVGProps> = ({ color, width, height }) => {
    return (
        <>
            <svg viewBox="-0.23 0 97.266 72.333" xmlns="http://www.w3.org/2000/svg" width="60" height="60">
                <path d="M56.897 0l6.624 9.29L97.036 0M22.075 72.333l18.13-17.725-10.912-11.71M54.152 0l-9.69 8.738 16.298.608M16.482 0l3.48 7.133 19.18 1.195M26.75 36.986l14.17-26.434L-.23 8.328M28.4 38.662L41.785 53.04 56.55 38.567l4.582-26.957-17.993-.918" fill={color}/>
            </svg>
        </>
    );
}
export default FreeLancerSVG;
