import React from "react";

interface RoundDeleteSVGProps {
    color: string;
    innerColor: string;
    width?: number | string;
    height?: number | string;
}
const RoundDeleteSVG : React.FC<RoundDeleteSVGProps> = ({ color, width, height, innerColor }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
                <circle cx="20.9189" cy="20" r="20" fill={color}/>
                <rect x="27.5859" y="19.1667" width="1.66667" height="13.3333" rx="0.833333" transform="rotate(90 27.5859 19.1667)" fill={innerColor}/>
            </svg>
        </>
    );
}
export default RoundDeleteSVG;
