import React from "react";

interface plusButtonSVGProps {
    color: string;
    plusColor: string;
    width?: number | string;
    height?: number | string;
}
const PlusButtonSVG : React.FC<plusButtonSVGProps> = ({ color,plusColor, width, height }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                <ellipse cx="20.5684" cy="20.3" rx="20.3133" ry="20.3" fill={color}/>
                <rect x="19.7219" y="13.5333" width="1.69277" height="13.5333" rx="0.846386" fill={plusColor}/>
                <rect x="27.3394" y="19.4542" width="1.69167" height="13.5422" rx="0.845833" transform="rotate(90 27.3394 19.4542)" fill={plusColor}/>
            </svg>
        </>
    );
}
export default PlusButtonSVG;
