import React from "react";

interface IndeedSVGProps {
    color: string;
    width?: number | string;
    height?: number | string;
}
const IndeedSVG : React.FC<IndeedSVGProps> = ({ color, width, height }) =>{
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="51" viewBox="0 0 40 51" fill="none">
                <g clip-path="url(#clip0_92_2163)">
                    <path d="M21.6581 28.2236C21.6581 30.8188 21.6581 33.4156 21.6581 36.0108C21.6581 36.5729 21.5931 37.1268 21.4198 37.666C21.1399 38.5343 20.591 39.2137 19.6972 39.647C19.3108 39.8344 18.8955 39.9403 18.4622 39.9908C17.8501 40.0624 17.247 40.0234 16.6638 39.8376C15.8134 39.5672 15.185 39.0605 14.7589 38.347C14.4628 37.8517 14.322 37.3141 14.2533 36.7602C14.2046 36.3611 14.1901 35.9603 14.1901 35.5595C14.1901 30.9214 14.1901 26.2833 14.1901 21.6468C14.1901 21.5865 14.1883 21.5246 14.192 21.4643C14.201 21.3649 14.2172 21.3519 14.3292 21.3568C14.6668 21.3698 15.0027 21.3926 15.3403 21.3991C16.4092 21.4171 17.4637 21.3161 18.5019 21.0831C19.1917 20.9283 19.8597 20.7165 20.5079 20.4526C20.842 20.3158 21.1652 20.1626 21.483 19.9981C21.4974 19.9899 21.5118 19.9834 21.5281 19.9769C21.6238 19.9378 21.6491 19.9492 21.6563 20.0421C21.6617 20.0975 21.6581 20.1545 21.6581 20.2099C21.6581 22.8817 21.6581 25.5534 21.6581 28.2252V28.2236Z" fill={color}/>
                    <path d="M17.6948 17.7727C14.5964 17.7939 12.0722 15.4935 12.0885 12.7012C12.1047 10.0099 14.4791 7.69328 17.7183 7.69165C20.9305 7.69165 23.3427 9.98546 23.3337 12.7599C23.3229 15.6988 20.6253 17.8037 17.6948 17.7727Z" fill={color}/>
                    <path d="M0.0020752 19.796C0.0201312 19.3057 0.0851327 18.8023 0.169996 18.3005C0.328888 17.3751 0.54556 16.4628 0.810983 15.5587C1.01682 14.8565 1.27863 14.169 1.56031 13.488C1.95212 12.5448 2.40171 11.6259 2.9145 10.7316C3.67105 9.41033 4.55038 8.15916 5.57234 6.99108C6.00208 6.50071 6.45709 6.03152 6.92835 5.5721C7.29669 5.2137 7.68309 4.87484 8.08032 4.54575C8.72672 4.00977 9.40563 3.50637 10.1224 3.04695C11.0361 2.46047 11.9985 1.94403 12.9988 1.48462C14.2085 0.930716 15.4869 0.569049 16.8104 0.32468C17.5055 0.195978 18.2079 0.101489 18.9157 0.0607608C19.5206 0.0265491 20.1273 0.00862864 20.7376 0.0444695C21.5772 0.0917142 22.4041 0.194349 23.2221 0.376812C24.7333 0.712412 26.1164 1.30053 27.3677 2.13301C28.1188 2.63316 28.7851 3.21475 29.3394 3.8941C29.585 4.19386 29.7818 4.51969 29.9244 4.86995C29.9678 4.97421 30.0021 5.08337 30.0003 5.19578C29.9967 5.35706 29.8901 5.44015 29.7132 5.41408C29.5958 5.39616 29.4857 5.35543 29.3791 5.30981C29.13 5.20066 28.8971 5.06707 28.675 4.91882C28.1405 4.56041 27.5735 4.24925 26.9958 3.95275C26.1381 3.51288 25.2516 3.12678 24.309 2.85146C23.4496 2.60057 22.5684 2.44092 21.6729 2.33991C20.8892 2.25031 20.102 2.22913 19.3147 2.25846C18.4878 2.28778 17.668 2.39042 16.8573 2.54844C15.3153 2.84657 13.8618 3.35975 12.4788 4.03421C11.4604 4.52946 10.507 5.11432 9.60244 5.76434C8.79534 6.34431 8.03698 6.97153 7.32558 7.64436C6.7514 8.18849 6.21153 8.76031 5.69512 9.35005C4.84108 10.3259 4.11884 11.3767 3.47243 12.4731C2.67075 13.8302 2.00448 15.2394 1.4303 16.686C1.12155 17.4664 0.838067 18.2532 0.572644 19.0466C0.471531 19.348 0.41014 19.6592 0.271109 19.9508C0.240414 20.016 0.204302 20.0795 0.16819 20.143C0.15194 20.1707 0.128467 20.1968 0.0887439 20.1886C0.0508263 20.1805 0.0399927 20.1495 0.0345759 20.1186C0.0147144 20.0192 -0.0033416 19.9182 0.00568639 19.7977L0.0020752 19.796Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_92_2163">
                        <rect width="30" height="40" fill="white" transform="translate(0 0.0249634)"/>
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}
export default IndeedSVG;
