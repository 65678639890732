import './QueryResults.styles.scss'
import React from "react";
import HorizontalBarChart from "../HorizontalBarChart/HorizontalBarChart";

interface QueryResultsProps{
    querySummary: any;

}
const QueryResults: React.FC<QueryResultsProps> = ({querySummary}) =>{
    return(
    <div
        className=" flex flex-col w-full h-fit justify-center items-center align-middle rounded-xl box-border mt-4"
        style={{
            borderRadius: "22px",
            border: "double 2px transparent",
            backgroundImage: "linear-gradient(#101010, #101010), linear-gradient(90deg,#FFF058,#D14238)",
            backgroundOrigin: "border-box",
            backgroundClip: "content-box, border-box",
        }}
    >
        <div className="flex flex-col w-full h-fit justify-center items-center px-6 py-4">
            {/* stats and headers*/}
            <div className="w-full h-full">
                {/*header div*/}
                <div className="graph-header w-fit my-4">Provider results for query</div>
                {/*graph div*/}
                <HorizontalBarChart chartData={querySummary}/>
            </div>

            {/*job source buttons*/}
            <div className="flex flex-col w-full mt-4" >
                {
                    (querySummary!==null) && querySummary.length>0 && querySummary.map((sum: { color: any; source: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; percentage: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; number: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; })=>{
                        return (
                            <div className="data-source-row">
                                <div className="card-text-container">
                                    <div className="bullet" style={{ border: ` 4px solid ${sum.color}` }}></div>
                                    <div className="card-text">{sum.source}</div>
                                </div>
                                <div className="card-numbers">{sum.number}</div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    </div>
    );
}
export default QueryResults;