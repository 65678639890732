import {combineReducers, configureStore} from '@reduxjs/toolkit'
import authReducer from "./reducers/authReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: "root",
    storage: storage,
};

const rootReducers = combineReducers({ auth: authReducer});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({
    reducer:persistedReducer,
})

export const persistor = persistStore(store);

export default store;
export type RootState = ReturnType<typeof store.getState>;