import './actions.style.scss'
import React, {useState} from "react";
import SendSVG from "../../custom/icons/SendSVG";
import DeleteSVG from "../../custom/icons/DeleteSVG";
import SaveSVG from "../../custom/icons/SaveSVG";
import {Modal} from "@mui/material";
import EditSVG from "../../custom/icons/EDITSVG";


interface ActionsProps {
    send: () => void;
    deleteWork: () => void;
    save: () => void;
    edit?: () => void;
    openModal: () => void;
    closeModal: () => void;
    editAIResponse: () => void;
    isModalOpen: boolean;

}

const Actions: React.FC<ActionsProps> = ({ send, deleteWork, save, openModal, closeModal, isModalOpen, editAIResponse }) => {

    return(
        <>
            {/*for the action buttons container*/}
            <div className="action-btn-container">
                <div className="send-btn" onClick={send}>
                    <SendSVG/>
                </div>
                <div className="delete-btn" onClick={openModal}>
                    <DeleteSVG/>
                </div>
                {/*<div className="edit-btn" onClick={editAIResponse}>
                    <EditSVG/>
                </div>
                <div className="save-btn" onClick={save}>
                    <SaveSVG/>
                </div>*/}
            </div>
            {/*modal for confirmation when the delete button is clicked*/}
            <Modal open={isModalOpen} onClose={closeModal}>
                <div className="fixed inset-0 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
                        <p className="text-gray-700 mb-6">Are you sure you want to delete this Job??</p>
                        <div className="flex justify-end">
                            <button
                                onClick={deleteWork}
                                className="px-4 py-2 text-white bg-red-500 rounded-lg mr-2"
                            >
                                Delete
                            </button>
                            <button
                                onClick={closeModal}
                                className="px-4 py-2 text-gray-700 bg-gray-200 rounded-lg"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default Actions;