import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
interface HorizontalBarChartProps {
    chartData: any;
}

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({chartData}) => {

    if(chartData !== null){
        const labels = chartData?.map((query: any) => query.source)
        const percentages = chartData?.map((query: any) => query.percentage)
        const colors = chartData?.map((query: any) => query.color)
        const options = {
            indexAxis: 'y' as const,
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                }
            },
            borderRadius: 20,
            borderSkipped: false,
        };

        const data = {
            labels,
            datasets: [
                {
                    data: percentages,
                    borderColor: 'transparent',
                    backgroundColor: colors,
                    barPercentage: 0.3,
                    categoryPercentage: 0.7,
                },
            ],
        };
        return <Bar options={options} data={data} />;
    } else {
        return (
            <>
                <h1 className="text-zyWhite">No Data Available</h1>
            </>
        );
    }


}
export default HorizontalBarChart









