import React from "react";

interface DownArrowSVGProps {
    color: string;
    width?: number | string;
    height?: number | string;
}
const DownArrowSVG : React.FC<DownArrowSVGProps> = ({ color, width, height }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" fill={color} />
            </svg>
        </>
    );
}
export default DownArrowSVG;
