import "./sidebar.styles.scss";
import React, {useEffect, useState} from "react";
import {CompleteWorkAPI, deleteWorkAPI, offerNotAvailableAPI, saveWorkAPI,} from "../../../api/api";
import Actions from "../actions/actions.components";
import Loading from "../../custom/Loaders/Loading/loading.component";
import {Modal} from "@mui/material";

interface SideBarProps {
  selectedWork: any;
  generatingAIResponse: boolean;
  generateAIResponse: () => void;
  removeJobById: (idToRemove: string) => void;
  editAIResponse: () => void;
  editResponse: boolean;
  textValue: string;
  setTextValue: (newTextValue: string) => void;
}

const SideBar: React.FC<SideBarProps> = ({
  selectedWork,
  generatingAIResponse,
  generateAIResponse,
  removeJobById,
  editAIResponse,
  editResponse,
  textValue,
  setTextValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [skillsArray, setSkillsArray] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);

  useEffect(() => {
    setSkillsArray(selectedWork?.skills);
    setIsLoading(false);
  }, [selectedWork]);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePopUp = () => {
    setIsPopUpOpen(false);
  };
  const send = () => {
    if (textValue !== "" || selectedWork?.aiResponse?.aiResponse != null) {
      setIsPopUpOpen(true);
      let jobUrl = findJobUrl(selectedWork);
      console.log("selected work", selectedWork);
      copyTextToClipboard()
        .then((res) => {
          console.log("Text copied to clipboard");
          // Open the link externally
          window.open(jobUrl, "_blank");
        })
        .then((err) => {
          console.log("error:", err);
        });
    } else {
      alert("Please first generate your response");
    }
  };

  const findJobUrl = (selectedWork: any): any => {
    for (const key in selectedWork) {
      if (Object.prototype.hasOwnProperty.call(selectedWork, key)) {
        const value = selectedWork[key];
        if (typeof value === "string" && value.startsWith("https:")) {
          return value; // Return the value that starts with "https:"
        }
      }
    }

    // If no value with "https:" is found, return null (or any other default value)
    return null;
  };

  /*this code copies the value of AI response to the clipboard*/
  const copyTextToClipboard = async () => {
    let text = textValue ? textValue : selectedWork?.aiResponse?.aiResponse;
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const deleteWork = () => {
    console.log(selectedWork, "job being deleted");
    closeModal();
    const workId = {
      id: selectedWork._id,
    };
    setIsLoading(true);
    deleteWorkAPI
      .deleteWork(JSON.stringify(workId))
      .then((response) => {
        setIsLoading(false);
        console.log("successfully  to deleted work");
        removeJobById(selectedWork._id);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("failed to delete work", error);
      });
  };

  const save = () => {
    setIsLoading(true);
    const payload = {
      jobId: selectedWork?.jobLink
        ? selectedWork?.jobLink
        : selectedWork?.jobUrl,
      type: selectedWork?.type,
    };

    saveWorkAPI
      .saveWork(payload)
      .then((res) => {
        console.log("successfully saved job");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error saving job", err);
        setIsLoading(false);
      });
  };
  const handleTextChange = (event: { target: { value: any } }) => {
    setTextValue(event.target.value);
  };

  const formatDate = (timeJobWasPosted: number | undefined) => {
    if (timeJobWasPosted) {
      const date = new Date(timeJobWasPosted);

      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });

      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });

      return `${formattedDate} ${formattedTime}`;
    }
  };

  const showMoreDescription = () => {
    setShowMore(true);
  };
  const showLessDescription = () => {
    setShowMore(false);
  };

  const offerSent = () => {
    const payload = {
      id: selectedWork._id,
      type: selectedWork.type,
    };
    CompleteWorkAPI.completeWork(payload)
      .then((res) => {
        console.log(res, "offerSent status saved successfully");
        /*then remove it from the list*/
        removeJobById(selectedWork._id);
      })
      .catch((err) => {
        console.log(err, "error saving offer sent job");
      });
    closePopUp();
  };
  const offerNotAvailable = () => {
    console.log(selectedWork);
    offerNotAvailableAPI
      .offerNotAvailable({ id: selectedWork._id })
      .then((res) => {
        console.log(res, "offer not available status saved successfully");
        /*then remove it from the list, as you should not complete the same job twice*/
        removeJobById(selectedWork._id);
      })
      .catch((err) => {
        console.log(err, "error saving offer not available status");
      });
    closePopUp();
  };

  // console.log( selectedWork?.aiResponse?.aiResponse, " ----->     aiResponse")
  // console.log(textValue, "  -------->   text value")

  // debugger
  return (
    <>
      <div className="w-3/5 overflow-auto" style={{ background: "#101010" }}>
        {isLoading ? (
          <div className="side-loader flex items-center justify-center top-1/2 left-1/2"></div>
        ) : (
          <div className="ml-16 w-[90%]">
            {/* Content for the sidebar */}
            <div className="text-zyYellow text-4xl not-italic font-extrabold leading-normal mt-9 flex-wrap w-full">
              {/*this is for the title*/}
              {selectedWork.title}
            </div>
            {/*div for details eg  creation time, revenue etc*/}
            <div className="text-zyWhite text-2xl not-italic font-bold leading-normal flex w-full flex-wrap h-fit flex-col mt-9">
              <div className="flex">
                <div>Potential Job Revenue: &nbsp;</div>
                <div style={{ color: "#FEC400" }}>{selectedWork.salary}</div>
              </div>
              <div className="flex">
                <div>Creation time: &nbsp;</div>
                <div style={{ color: "#FEC400" }}>
                  {formatDate(selectedWork?.creation)}
                </div>
              </div>
              <div className="flex mt-2.5">
                <div className="mr-2.5">Tag:</div>
                <div className="flex flex-wrap w-[80%]">
                  {skillsArray?.map((skill: string, index: number) => (
                    <div
                      className="skill-tags flex items-center justify-center rounded-3xl w-fit mr-2.5 mb-1 h-9 py-0 px-2.5"
                      key={index}
                    >
                      {skill
                        .replace(/\r?\n|\r/g, " ")
                        .replace(/\s+/g, " ")
                        .trim()}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/*for the job description*/}
            <div className="text-zyWhite text-2xl not-italic font-bold leading-normal flex w-[80%] flex-wrap h-fit flex-col mt-9">
              <div className="job-header mb-2.5">Job description:</div>
              {selectedWork.hasOwnProperty("shortDescription") ? (
                <>
                  {!showMore && (
                    <div
                      className="h-fit flex flex-row flex-wrap w-full"
                      style={{ color: "#748AA1" }}
                    >
                      {selectedWork.shortDescription}
                      <button
                        type="button"
                        onClick={showMoreDescription}
                        className="focus:outline-none text-black bg-zyGreen hover:bg-zyGreenHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                      >
                        Show More
                      </button>
                    </div>
                  )}
                  {showMore && (
                    <div
                      className="h-fit flex flex-row flex-wrap w-full"
                      style={{ color: "#748AA1" }}
                    >
                      {selectedWork.description}
                      <button
                        type="button"
                        onClick={showLessDescription}
                        className="focus:outline-none text-black bg-zyGreen hover:bg-zyGreenHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                      >
                        Show Less
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="h-fit flex flex-wrap w-full"
                  style={{ color: "#748AA1" }}
                >
                  {selectedWork.description}
                </div>
              )}
            </div>

            <div className="text-zyWhite text-2xl not-italic font-light leading-normal flex w-[80%] flex-wrap h-fit flex-col mt-9 ">
              AI response:
            </div>
            {selectedWork?.aiResponse?.aiResponse ? (
              <textarea
                // disabled={!editResponse}
                value={
                  textValue ? textValue : selectedWork?.aiResponse?.aiResponse
                }
                className="aiText text-base not-italic font-bold leading-normal flex w-[80%] flex-col h-96 mt-1 rounded-3xl p-2.5 mb-12 overflow-auto outline-none"
                onChange={handleTextChange}
              />
            ) : generatingAIResponse ? (
              <Loading />
            ) : (
              <button
                type="button"
                style={{
                  display: textValue !== "" ? "none" : "block",
                }}
                onClick={generateAIResponse}
                className="focus:outline-none text-black bg-zyGreen hover:bg-zyGreenHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
              >
                Generate AI Response
              </button>
            )}
            {textValue !== "" && (
              <textarea
                // disabled={!editResponse}
                value={textValue}
                className="aiText text-base not-italic font-bold leading-normal flex w-[80%] flex-col h-96 mt-1 rounded-3xl p-2.5 mb-12 overflow-auto outline-none"
                onChange={handleTextChange}
              />
            )}
          </div>
        )}
      </div>
      {/*modal for pop up to follow through with the job click */}
      <Modal open={isPopUpOpen} onClose={closePopUp}>
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Operation </h2>
            <p className="text-gray-700 mb-6 font-semibold">
              How did the application go?
            </p>
            <div className="flex justify-end">
              <button
                onClick={offerSent}
                className="px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors mr-2"
              >
                Offer sent
              </button>
              <button
                onClick={offerNotAvailable}
                className="px-4 py-2  font-semibold text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors mr-2"
              >
                Offer no longer available
              </button>
              <button
                onClick={closePopUp}
                className="px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
              >
                Offer not sent
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Actions
        send={send}
        deleteWork={deleteWork}
        save={save}
        openModal={openModal}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        editAIResponse={editAIResponse}
      />
    </>
  );
};
export default SideBar;
