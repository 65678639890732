import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeSVG from "../../custom/icons/HomeSVG";
import SearchSVG from "../../custom/icons/SearchSVG";
import ProfileSVG from "../../custom/icons/ProfileSVG";
import LogOutSVG from "../../custom/icons/LogOutSVG";
import Logo from "../../custom/icons/LogoSVG";
import "./navbar.styles.scss";
import NavSaveSVG from "../../custom/icons/NavSaveSVG";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface NavBarTwoProps {
  logOut: () => void;
}
const NavBar: React.FC<NavBarTwoProps> = ({ logOut }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const gradientColor = `linear-gradient(90deg, #FFF058, #D14238)`;

  const headersData = [
    {
      component: <HomeSVG color={"/" === location.pathname ? "#FEC400" : "#FFFFFF"} width="40px" height="30px" />,
      href: "/",
    },
    {
      component: <SearchSVG color={"/search" === location.pathname ? "#FEC400" : "#FFFFFF"} />,
      href: "search",
    },
    {
      component: <NavSaveSVG color={"/job" === location.pathname ? "#FEC400" : "#FFFFFF"} />,
      href: "job",
    },
  ];
  const profilePicture = useSelector((state: RootState) => state.auth.picture);

  return (
    <>
      <div className="flex flex-col h-full items-center px-2 w-fit">
        {/* Logo div */}
        <div className="h-fit py-4 mt-9">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="flex items-center justify-center"
          >
            <Logo />
          </div>
        </div>

        {/* Navbar items div */}
        <div className="flex flex-col h-full mt-12.5 justify-between w-fit">
          <div className="flex flex-col h-full justify-center items-center gap-8 w-fit">
            {headersData.length > 0 &&
              headersData.map(({ component, href }) => {
                return (
                  <div
                    key={href}
                    onClick={() => {
                      navigate(href);
                    }}
                    className="menu-item p-4 flex items-center aspect-square justify-center opacity-100 transition-opacity rounded w-full h-16"
                  >
                    {component}
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col h-full justify-center gap-8 w-fit">
            {/* Logout & Profile div */}
            <div onClick={logOut} className="menu-item p-4 flex items-center aspect-square justify-center opacity-100 transition-opacity rounded h-16">
              <LogOutSVG color={"/logout" === location.pathname ? "#FEC400" : "#FFFFFF"} />
            </div>
            <div
              onClick={() => {
                navigate("profile");
              }}
              className="menu-item p-2 flex items-center w-16 justify-center opacity-100 transition-opacity rounded h-16"
            >
              {profilePicture ? <img src={profilePicture} alt="Profile Picture" className=" rounded-full w-full  h-auto" /> : <ProfileSVG />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NavBar;
