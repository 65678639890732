import "./search.styles.scss";
import React, {useEffect, useRef, useState} from "react";
import Dropdown from "../../custom/DropDown";
import PlusButtonSVG from "../../custom/icons/plusButtonSVG";
import RoundDeleteSVG from "../../custom/icons/RoundDeleteSVG";
import QueryResults from "../../custom/QueryResults/QueryResults.component";
import {createQueryAPI, deleteQueryAPI, getQueryDataAPI} from "../../../api/api";
import LoaderWholePage from "../../custom/Loaders/LoaderWholePage/LoaderWholePage.component";
import {Modal} from "@mui/material";

interface QueryInterface {
    _id: string;
    title: string;
    location?: string;
    scraped?: number;
    selected?: number;
}

const Search = () => {
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [selectedCountry, setSelectedCountry] = useState<string>("");
    const [isHovered, setIsHovered] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState<string | null>(null);
    const [hoveredId, setHoveredId] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    /*setting saved variable helps to reload the new updated list of queries*/
    const [saved, setSaved] = useState<boolean>(false);
    const [isOpenCountry, setIsOpenCountry] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownRefCountry = useRef<HTMLDivElement>(null);

    const [queries, setQueries] = useState<QueryInterface[]>([]);
    const [queryStats, setQueryStats] = useState<any>([]);
    const [querySummary, setQuerySummary] = useState<any>(null);
    const [pageLoader, setPageLoader] = useState<boolean>(true);
    const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
    const [queryID, setQueryID] = useState<any>(null);

    const options = [
        {id: 1, label: "React"},
        {id: 2, label: "React Native"},
        {id: 3, label: "UI/UX Design"},
        {id: 4, label: "Copyrighting"},
        {id: 5, label: "Social Media Manager"},
        {id: 6, label: "Javascript"},
        {id: 7, label: "Node.js"},
        {id: 8, label: "Python"},
        {id: 9, label: "Wordpress"},
        {id: 10, label: "Gsap"},
        {id: 11, label: "Three.js"},
        {id: 12, label: "Angular"},
        {id: 13, label: "Vue.js"},
        {id: 14, label: "OpenAi"},
        {id: 15, label: "Lang chain"},
        {id: 16, label: "Typescript"},
        {id: 17, label: "Prompt Engineering"},
        {id: 18, label: "Frontend"},
        {id: 19, label: "SASS"},
        {id: 20, label: "Developer"},
        {id: 21, label: "Administrative Assistant"},
        {id: 22, label: "Executive Assistant"},
        {id: 23, label: "Marketing Manager"},
        {id: 24, label: "Customer Service Representative"},
        {id: 25, label: "Nurse Practitioner"},
        {id: 26, label: "Software Engineer"},
        {id: 27, label: "Sales Manager"},
        {id: 28, label: "Data Entry Clerk"},
        {id: 29, label: "Office Assistant"},
        {id: 30, label: "Librarian"},
        {id: 31, label: "Project Manager"},
        {id: 32, label: "Account Executive"},
        {id: 33, label: "Travel Nurse"},
        {id: 34, label: "Nurse Practitioner"},
        {id: 35, label: "Doctor"},
        {id: 36, label: "Caregiver"},
        {id: 37, label: "Hotel Receptionist"},
        {id: 38, label: "Reservationist"},
        {id: 39, label: "Events Manager"},
        {id: 40, label: "Event Planner"},
        {id: 41, label: "Hotel Manager"},
    ];

    const countries = [
        {id: 1, label: "United States"},
        {id: 2, label: "Canada"},
        {id: 3, label: "United Kingdom"},
        {id: 4, label: "Germany"},
        {id: 5, label: "France"},
        {id: 6, label: "Australia"},
        {id: 7, label: "Japan"},
        {id: 8, label: "Brazil"},
        {id: 9, label: "Italy"},
        {id: 10, label: "Spain"},
        {id: 11, label: "Portugal"},
        {id: 12, label: "Belgium"},
        {id: 13, label: "Switzerland"},
        {id: 14, label: "Austria"},
    ];

    const color = isHovered ? "#29CB9780" : "#29CB97";

    /*this array is responsible for the colors in the graphs*/
    const colorArray = ["#4072EE", "#FEC400", "#B558F6", "#29CB97", "#2ed6f6", "#e7465c"];

    useEffect(() => {
        getQueryData();
    }, [saved]);

    const getQueryData = () => {
        getQueryDataAPI
            .getQueryData()
            .then((res) => {
                setPageLoader(false)
                console.log("query results", res.data);
                setQueries(res.data.queries);
                setQueryStats(res.data.queryStats);
                selectFirstQuery(res.data.queries[0], res.data.queryStats);
                setSaved(false)
            })
            .catch((err) => {
                setPageLoader(false)
                console.log("error fetching query data", err);
                setSaved(false)
            });
    };

    useEffect(() => {
        const handleOutsideClick = (event: { target: any }) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }

            if (dropdownRefCountry.current && !dropdownRefCountry.current.contains(event.target)) {
                setIsOpenCountry(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    const toggleOptionDropdown = () => {
        setIsOpen(!isOpen);
    };
    const toggleCountryDropdown = () => {
        setIsOpenCountry(!isOpenCountry);
    };

    const completeGraph = (query: QueryInterface, stats: any) => {
        console.log(query, "the query");
        setSelectedQuery(query._id);
        let queryTitle = query?.title;
        let queryLocation = query.location === undefined ? "" : query.location;
        /* get the stats and prepare the data for presentation*/
        let queryStatistics = stats.filter((q: any) => q._id === (queryLocation !== "" ? queryLocation.toLocaleLowerCase() : "") + queryTitle?.toLocaleLowerCase());

        if (queryStatistics.length > 0) {
            let queryTotal = queryStatistics[0]["queried"];
            const {queried, _id, ...filteredStats} = queryStatistics[0];

            console.log(filteredStats, "filtered stats");
            let finalChartData: {
                id: number;
                source: string;
                percentage: number;
                number: number;
                color: string;
            }[] = [];
            Object.entries(filteredStats).forEach(([key, value], index) => {
                if (typeof value === "number") {
                    // Do something with the key-value pair and index
                    console.log(`Index: ${index}, Key: ${key}, Value: ${value}`);
                    finalChartData.push({
                        id: index,
                        source: key,
                        percentage: Math.round((value / queryTotal) * 100),
                        number: value,
                        color: colorArray[index],
                    });
                }
            });
            setQuerySummary(finalChartData);
        } else {
            setQuerySummary(null);
        }
    };

    const selectFirstQuery = (query: QueryInterface, stats: any) => {
        completeGraph(query, stats);
    };
    const selectQuery = (query: QueryInterface) => {
        completeGraph(query, queryStats);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleDeleteMouseEnter = (id: string) => {
        // setIsDeleteHovered(true);
        setHoveredId(id);
    };
    const handleDeleteMouseLeave = (id: string) => {
        setHoveredId(null);
    };

    const handleOptionChange = (optionId: string) => {
        setSelectedOption(optionId);
        toggleOptionDropdown();
    };

    const handleCountriesChange = (optionId: string) => {
        setSelectedCountry(optionId);
        toggleCountryDropdown();
    };

    /*the code that is going to delete the query*/
    const deleteQuery = (id: string) => {
        closePopUp()
        console.log("Id to be deleted: " + id);
        let body = {
            id: id,
        };
        console.log("delete query body", body);
        deleteQueryAPI
            .deleteQuery(body)
            .then((res) => {
                console.log(res, "successfully deleted query");
                setSaved(true);
            })
            .then((err) => {
                console.log("Error deleting query: ", err);
            });
    };

    /*this function clears selections*/
    const clearSelections = () => {
        setSelectedOption("");
        setSelectedCountry("");
    };

    /*this method returns the code of the country*/
    /*const countryCode = (searchValue: string) => {
      const matchedObject = Countries.find((obj) => {
        const lowerCasedValues = Object.values(obj.name).flatMap((value) => {
          if (typeof value === "string") {
            return [value.toLowerCase()];
          } else {
            return Object.values(value).flatMap((subValue) => {
              return typeof subValue === "string" ? [subValue.toLowerCase()] : [];
            });
          }
        });

        return lowerCasedValues.includes(searchValue.toLowerCase());
      });

      return matchedObject ? matchedObject.cca2 : undefined;
    };*/

    /* this method will handle adding of a query for LinkedIn*/
    const addQuery = () => {
        /* build the payload*/
        let payload = {
            title: selectedOption,
            location: selectedCountry,
        };
        if (selectedCountry !== "" && selectedOption !== "") {
            /* clear selections*/
            clearSelections();

            createQueryAPI
                .createQuery(payload)
                .then((res) => {
                    console.log(res, "created query successfully");
                    setSaved(true);
                })
                .catch((err) => {
                    console.log("error creating query", err);
                });
        } else {
            alert("Please Select both Query and Country and Try Again");
        }
    };

    const closePopUp = () => {
        setIsPopUpOpen(false);
    };
    const openPopUp = (queryID: any) => {
        setIsPopUpOpen(true);
        setQueryID(queryID)
    };

    /*  /!*for upwork*!/
      const addUpworkQuery = () => {
        /!* build the payload*!/
        let payload = {
          rows: 50,
          infinite: true,
          titles: [selectedOption],
          location: [selectedCountry],
        };
        if (selectedCountry !== "" && selectedOption !== "") {
          /!* clear selections*!/
          clearSelections();

          createQueryAPIUpwork
            .createQuery(payload)
            .then((res) => {
              console.log(res, "created query successfully");
              setSaved(true);
            })
            .catch((err) => {
              console.log("error creating query", err);
            });
        } else {
          alert("Please Select both Query and Country and Try Again");
        }
      };
      /!*for freelancer*!/
      const addFreelancerQuery = () => {
        /!* build the payload*!/
        let payload = {
          rows: 50,
          infinite: true,
          titles: [selectedOption],
        };
        if (selectedCountry !== "" && selectedOption !== "") {
          /!* clear selections*!/
          clearSelections();

          createQueryAPIFreelancer
            .createQuery(payload)
            .then((res) => {
              console.log(res, "created query successfully");
              setSaved(true);
            })
            .catch((err) => {
              console.log("error creating query", err);
            });
        } else {
          alert("Please Select both Query and Country and Try Again");
        }
      };
      /!*for indeed*!/
      const addIndeedQuery = () => {
        let code = countryCode(selectedCountry);
        console.log("this is the country code:", code);
        /!* build the payload*!/
        let payload = {
          rows: 50,
          infinite: true,
          titles: [selectedOption],
          locations: [""],
          country: selectedCountry,
        };
        if (selectedCountry !== "" && selectedOption !== "") {
          /!* clear selections*!/
          clearSelections();

          createQueryAPIIndeed
            .createQuery(payload)
            .then((res) => {
              console.log(res, "created query successfully");
              setSaved(true);
            })
            .catch((err) => {
              console.log("error creating query", err);
            });
        } else {
          alert("Please Select both Query and Country and Try Again");
        }
      };*/


    if (pageLoader) {
        return <LoaderWholePage/>

    } else {
        return (
            <div className="pl-10 w-full mt-8">
                {/*this div is for the title*/}
                <div className="header">Here your search console</div>

                {/*this div is for all the other content*/}
                <div className="flex mt-20 gap-4 max-h-screen">
                    {/* for new Query */}
                    <div className="w-2/5 ">
                        <div className="new-query">New Query:</div>
                        {/*for the dropdowns*/}
                        <div className={"mt-4 flex flex-row"}>
                            <div className="flex flex-row w-[80%]">
                                <div className="mr-10">
                                    <Dropdown
                                        dropDownLabel="Query"
                                        options={options}
                                        handleOptionChange={handleOptionChange}
                                        selectedOptions={selectedOption}
                                        isOpen={isOpen}
                                        toggleDropdown={toggleOptionDropdown}
                                        dropdownRef={dropdownRef}
                                    />
                                </div>
                                <div className="mr-10">
                                    <Dropdown
                                        dropDownLabel="Countries"
                                        options={countries}
                                        handleOptionChange={handleCountriesChange}
                                        selectedOptions={selectedCountry}
                                        isOpen={isOpenCountry}
                                        toggleDropdown={toggleCountryDropdown}
                                        dropdownRef={dropdownRefCountry}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row w-[20%] justify-end">
                                <div style={{cursor: "pointer"}} onMouseEnter={handleMouseEnter}
                                     onMouseLeave={handleMouseLeave} onClick={addQuery}>
                                    <PlusButtonSVG color={color} plusColor="#000000"/>
                                </div>
                            </div>
                        </div>
                        {/*<div className="flex">
                          <button
                              type="button"
                              onClick={addQuery}
                              // disabled={(selectedCountry !== "") && (selectedOption !== "")}
                              className="focus:outline-none text-black bg-zyLinkedin hover:bg-zyLinkedinHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                          >
                              LinkedIn
                          </button>
                          <button type="button" onClick={addIndeedQuery} className="focus:outline-none text-black bg-zyIndeed hover:bg-zyIndeedHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2">
                              Indeed
                          </button>
                          <button
                              type="button"
                              onClick={addFreelancerQuery}
                              className="focus:outline-none text-black bg-zyFreelancer hover:bg-zyFreelancerHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                          >
                              Freelancer
                          </button>
                          <button type="button" onClick={addUpworkQuery} className="focus:outline-none text-black bg-zyUpwork hover:bg-zyUpworkHover font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2">
                              Upwork
                          </button>
                      </div>*/}
                        <QueryResults querySummary={querySummary}/>
                    </div>

                    {/* for total queries */}
                    <div className="w-3/5">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            {/*for the label*/}
                            <div className="new-query">Total Queries:</div>

                            {/*for the number of queries*/}
                            <div className="query-number">{queries.length}</div>
                        </div>

                        {/*for the table*/}
                        <div className="w-full flex justify-between items-center p-4">
                            <div className="flex justify-items-center w-1/5 table-header">Query</div>
                            <div className="flex justify-items-center w-1/5 table-header">Country</div>
                            <div className="flex justify-items-center w-1/5 table-header">Scrapped Jobs</div>
                            {/*<div className="flex justify-items-center w-1/5 table-header">Selected Jobs</div>*/}
                            <div className="table-header w-1/5"></div>
                        </div>
                        <div
                            className="w-full flex flex-col gap-4 justify-between items-center p-4 overflow-y-auto max-h-[70%]">
                            {queries &&
                                queries.length > 0 &&
                                queries.map((query) => {
                                    let isSelected = selectedQuery === query._id;
                                    const isHovered = hoveredId === query._id;
                                    return (
                                        <div
                                            key={query._id}
                                            className={` w-full flex flex-row hover:cursor-pointer h-fit min-h-[4rem] justify-between items-center +  ${
                                                isSelected ? "border-2 border-double border-transparent " : " border-none"
                                            }`}
                                            style={{
                                                borderRadius: "22px",
                                                border: isSelected ? "double 2px transparent" : "transparent",
                                                backgroundImage: isSelected ? "linear-gradient(#101010, #101010), linear-gradient(90deg,#FFF058,#D14238)" : "none",
                                                backgroundOrigin: "border-box",
                                                backgroundClip: "content-box, border-box",
                                            }}
                                            onClick={() => selectQuery(query)}
                                        >
                                            <div
                                                className=" w-full flex flex-row h-fit justify-between items-center py-4 px-2">
                                                <div
                                                    className="table-text flex justify-items-center w-1/5">{query?.title}</div>
                                                <div
                                                    className="table-text flex justify-items-center w-1/5">{query?.location}</div>
                                                <div
                                                    className="table-text flex justify-items-center w-1/5">{query?.scraped}</div>
                                                {/*<div className="table-text flex justify-items-center w-1/5">{query?.selected}</div>*/}
                                                <div
                                                    className="table-text flex justify-items-center w-1/5"
                                                    style={{cursor: "pointer"}}
                                                    onMouseEnter={() => handleDeleteMouseEnter(query._id)}
                                                    onMouseLeave={() => handleDeleteMouseLeave(query._id)}
                                                    onClick={() => openPopUp(query._id)}
                                                >
                                                    <RoundDeleteSVG color={isHovered ? "#FC240280" : "#FC2402"}
                                                                    innerColor="#FFFFFF"/>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                {/*modal for pop up to follow through with the job click */}
                <Modal open={isPopUpOpen} onClose={closePopUp}>
                    <div className="fixed inset-0 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <h2 className="text-xl font-bold mb-4">Confirm Operation </h2>
                            <p className="text-gray-700 mb-6 font-semibold">
                                Are you sure you want to delete this query?
                            </p>
                            <div className="flex justify-end">
                                <button
                                    onClick={closePopUp}
                                    className="px-4 py-2  font-semibold text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => deleteQuery(queryID)}
                                    className="px-4 py-2 font-semibold text-zyBlack bg-zyRed rounded-lg hover:bg-red-700"
                                >
                                    Delete Query
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }


};
export default Search;
