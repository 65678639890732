import axios, {AxiosResponse} from "axios";
import {LoginData, LoginResponse} from "../models/login";
import {WorksResponse} from "../models/jobs";
import {PaginationParams, workDetailsParams} from "../models/params";
import {WorkDetail} from "../models/workDetail";
import {SaveJobResponse} from "../models/saveJobResponse";
import {UserInterface} from "../models/user";
import {AIResponse} from "../models/aiResponse";
import {QueryData} from "../models/queries";

// let authToken: string | null = localStorage.getItem("jwt");
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const headers = {
  "Content-Type": "application/json",
};

const loginInstance = axios.create({
  baseURL: apiUrl,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
});

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 300000,
  headers: headers,
});

interface loginInterface {
  login: (payload: LoginData) => Promise<AxiosResponse<LoginResponse>>;
}
export const loginAPI: loginInterface = {
  login: (payload: LoginData) => loginInstance.post<string, any, LoginData>(`api/auth/login/google`, payload),
};

interface worksInterface {
  getWorks: (params: PaginationParams) => Promise<AxiosResponse<WorksResponse>>;
}

export const worksAPI: worksInterface = {
  getWorks: (params: PaginationParams) => axiosInstance.get("api/works", { params }),
};

interface workDetailInterface {
    getWorkDetail: (params: workDetailsParams) => Promise<AxiosResponse<WorkDetail>>;
}

export const workDetailsAPI: workDetailInterface = {
    getWorkDetail: (params: workDetailsParams) => axiosInstance.get("api/works/details", { params }),
};

interface deleteWorkInterface {
    deleteWork: (payload: any) => Promise<AxiosResponse<any>>;
}

export const deleteWorkAPI: deleteWorkInterface = {
    deleteWork: (payload: any) => axiosInstance.post("api/works/hide", payload),
};

interface completeWorkInterface {
    completeWork: (payload: any) => Promise<AxiosResponse<any>>;
}

export const CompleteWorkAPI: completeWorkInterface = {
    completeWork: (payload: any) => axiosInstance.put("api/works/complete", payload),
};

/*to save a job*/
interface saveWorkInterface {
    saveWork: (payload: any) => Promise<AxiosResponse<SaveJobResponse>>;
}

export const saveWorkAPI: saveWorkInterface = {
    saveWork: (payload: any) => axiosInstance.post("api/user/saveWork", payload),
};

/*to retrieve details about the user*/
interface getUserDetailsInterface {
    getUserDetails: () => Promise<AxiosResponse<UserInterface>>;
}

export const userDetailsAPI: getUserDetailsInterface = {
    getUserDetails: () => axiosInstance.get("api/user"),
};

/*to generate ai response */
interface generateAiResponseInterface {
    generateAiResponse: (payload: any) => Promise<AxiosResponse<AIResponse>>;
}

export const generateAiResponseAPI: generateAiResponseInterface = {
    generateAiResponse: (payload: any) => axiosInstance.post("api/works/createAiResponse", payload),
};

/*for getting query data on the search page*/
interface queryInterface {
    getQueryData: () => Promise<AxiosResponse<QueryData>>;
}

export const getQueryDataAPI: queryInterface = {
    getQueryData: () => axiosInstance.get("api/query"),
};

/*create a query, on the search page*/
interface createQueryInterface {
    createQuery: (payload: any) => Promise<AxiosResponse<any>>;
}

/* many methods following the same interface but to different apis*/

/*linkedin*/
export const createQueryAPI: createQueryInterface = {
    createQuery: (payload: any) => axiosInstance.post("api/query", payload),
};
/*/!*indeed*!/
export const createQueryAPIIndeed: createQueryInterface = {
    createQuery: (payload: any) => axiosInstance.post("api/indeedScraper/createQuery", payload),
};
/!*freelancer*!/
export const createQueryAPIFreelancer: createQueryInterface = {
    createQuery: (payload: any) => axiosInstance.post("api/freelancerScraper/createQuery", payload),
};
/!*upwork*!/
export const createQueryAPIUpwork: createQueryInterface = {
    createQuery: (payload: any) => axiosInstance.post("api/upworkScraper/createQuery", payload),
};*/

/* for deleting query*/
interface deleteQueryInterface {
    deleteQuery: (payload: any) => Promise<AxiosResponse<any>>;
}

export const deleteQueryAPI: deleteQueryInterface = {
    deleteQuery: (payload: any) => axiosInstance.post("api/query/delete", payload),
};

/*offer is no longer available*/
interface offerNotAvailableInterface {
    offerNotAvailable: (payload: any) => Promise<AxiosResponse<any>>;
}

export const offerNotAvailableAPI: offerNotAvailableInterface = {
    offerNotAvailable: (payload: any) => axiosInstance.post("api/works/delete", payload),
};

axiosInstance.interceptors.request.use(
    (config) => {
      let authToken: string | null = localStorage.getItem("jwt");

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      // console.log("request config", config);
      return config;
    },
    (error) => {
      // console.log("request error", error);
      return Promise.reject(error);
    }
);
