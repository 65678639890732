import './home.styles.scss'
import React, {useCallback, useEffect, useRef, useState} from "react";
import SideBar from "../sidebar/sidebar.component";
import {generateAiResponseAPI, workDetailsAPI, worksAPI} from "../../../api/api";
import {PaginationParams, workDetailsParams} from "../../../models/params";
import {logout} from "../../../redux/reducers/authReducer";
import {useDispatch, useSelector} from "react-redux";
import TiltedZooYouSVG from "../../custom/icons/TiltedZooYou";
import IconRenderer from "../../custom/IconRenderer/iconRenderer.component";
import {RootState} from "../../../redux/store";
import LoaderWholePage from "../../custom/Loaders/LoaderWholePage/LoaderWholePage.component";


interface HomeProps {

}

const Home: React.FC<HomeProps> = ({}) => {
    const dispatch = useDispatch();
    const [selectedWork, setSelectedWork] = useState<any>({})
    const [jobs, setJobs] = useState([{}])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);
    // const[generatedAIResponse, SetGeneratedAIResponse] = useState<string>("");
    const [generatingAIResponse, setGeneratingAIResponse] = useState(false);
    const [editResponse, setEditResponse] = useState(false);
    const [pageLoader, setPageLoader] = useState<boolean>(true);
    const [textValue, setTextValue] = useState<string>("");

    const editAIResponse = () =>{
        setEditResponse(true)
    }

    /*get the name from redux store*/
    const given_name = useSelector((state: RootState) => state.auth.given_name);

    useEffect(() => {
        getJobs().then(r => {
            console.log("successfully got jobs")
        }).catch((err) => {
            alert("Failed to get Jobs")
        });
    }, [currentPage])

    const generateAIResponse =()=>{
        setGeneratingAIResponse(true)
        let requestBody = {
            work: selectedWork
        }
        generateAiResponseAPI.generateAiResponse(requestBody).then((res)=>{
            setTextValue(res.data.aiResponse.aiResponse)
            setGeneratingAIResponse(false)
        }).catch((error)=>{
            console.log(error)
            setGeneratingAIResponse(false)
        })
    }
    const getJobs = async () => {
        setIsLoading(true);
        if(currentPage === 1){
            setPageLoader(true)
        }
        const params: PaginationParams = {
            limit: 8,
            page: currentPage,
        }
        worksAPI.getWorks(params).then((response) => {
            setIsLoading(false);
            setPageLoader(false);
            setJobs((prevJobs) => [...prevJobs, ...response.data.works]);
            let firstJob = response.data.works[0]
            if(currentPage === 1){
                setSelectedWork(firstJob)
                getSelectedJobDetails(firstJob._id, firstJob.type)
            }
        }).catch((err) => {
            console.log(err)
            setIsLoading(false);
            setPageLoader(false);
            if (err.request.status === 401) {
                dispatch(logout())
            }
        })
    }

    const lastBookElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (isLoading) return; // Prevent fetching data while already loading
            if (observer.current) observer.current.disconnect(); // Disconnect previous observer

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setIsLoading(true);
                    setCurrentPage((currentPage) => currentPage + 1); // Increment the page to fetch the next batch of data
                }
            });

            if (node) observer.current.observe(node); // Observe the last element
        },
        [isLoading]
    );

    const getSelectedJobDetails = async (id: string, type: string) => {
        console.log(id, type, "params")
        /*getting details of the first job*/
        const detailParams: workDetailsParams = {
            id: id,
            type: type,
        }

        workDetailsAPI.getWorkDetail(detailParams).then((response) => {
            setSelectedWork(response.data.work)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getJobDetail = (job: any) => {
        getSelectedJobDetails(job._id, job.type).then((res) => {
            // SetGeneratedAIResponse("")
            setTextValue("")
            setEditResponse(false)
            console.log("successfully got work detail")
        }).catch((err) => {
            console.log("error getting work detail", err)
        })
    }

    const removeJobById = (idToRemove: any) => {
        let nextIndex;
        const updatedJobs = jobs.filter((job: any, index) => {
            if (job._id === idToRemove) {
                nextIndex = index;
                return false;
            }
            return true;
        })
        setJobs(updatedJobs);
        if(nextIndex){
            let nextJob: any = updatedJobs[nextIndex]
            let jobId: any =nextJob?._id
            let jobType: any =nextJob?.type
            console.log("params", jobId, jobType)
            if(jobId && jobType){
                getSelectedJobDetails(jobId, jobType)
            } else {
                console.log("missing job id and type")
            }
        }
    };

    if (pageLoader){
        return <LoaderWholePage />
    } else {
        return (
            <>
                {/* this is where the home will be*/}
                <div className="w-2/5  block ml-14" style={{background: "#101010"}}>
                    {/* Content for the job waterfall */}
                    <div className="user-greeting mt-8">
                        Hi &nbsp; {given_name}!
                    </div>
                    {/*displays the jobs in form of cards*/}
                    <div className="overflow-auto mt-1 h-full">
                        {
                            jobs && jobs.length > 0 && jobs.map((job: any, i) => {
                                const isLastElement = jobs.length === i + 1;
                                let isSelected = (selectedWork?._id === job?._id)
                                if(job._id){
                                    return (
                                        <div
                                            key={job?.id}
                                            onClick={()=> {getJobDetail(job)}}
                                            className="flex w-[80%] h-48 rounded-3xl justify-center items-center bg-origin-border relative mt-9 hover:bg-zyBlackHover focus:bg-zyBlack"
                                            ref={isLastElement? lastBookElementRef : null}
                                            style={{
                                                border: isSelected? "double 2px transparent" : "transparent",
                                                backgroundImage: isSelected? "linear-gradient(#101010, #101010), linear-gradient(90deg,#FFF058,#D14238)" : "none",
                                                backgroundClip: "content-box, border-box",
                                            }}
                                        >
                                            <div className="w-[20%] flex items-center justify-center -mt-8">
                                                <IconRenderer value={job?.type} isSelected={isSelected} />
                                            </div>
                                            <div className="w-[80%] flex flex-col items-start justify-center">
                                                <div
                                                    className="text-4xl not-italic font-bold leading-normal flex-wrap"
                                                    style={{color: isSelected? "#FEC400": "white"}}>
                                                    {job.salary}
                                                </div>
                                                <div
                                                    className="not-italic font-bold leading-normal w-full flex-wrap overflow-hidden relative text-ellipsis text-2xl"
                                                    style={{
                                                        color: isSelected? "#FEC400": "white",
                                                    }}>
                                              <span
                                                  className="inline-block max-w-full align-middle overflow-hidden text-ellipsis"
                                              >
                                                {job?.title?.substring(0,35)}
                                              </span>
                                                    <span
                                                        className="absolute top-0 left-0 whitespace-normal invisible"
                                                    >
                                               {job?.title}
                                              </span>
                                                </div>
                                            </div>
                                            {isSelected &&
                                                <div className="absolute top-2 right-2">
                                                    <TiltedZooYouSVG/>
                                                </div>
                                            }
                                        </div>
                                    );

                                }

                            })
                        }
                        {
                            isLoading && <span className="loader"></span>
                        }
                    </div>
                </div>
                <SideBar
                    selectedWork={selectedWork}
                    generatingAIResponse={generatingAIResponse}
                    generateAIResponse={generateAIResponse}
                    removeJobById={removeJobById}
                    editAIResponse={editAIResponse}
                    editResponse={editResponse}
                    textValue={textValue}
                    setTextValue={setTextValue}
                />
            </>
        )

    }


}
export default Home;