import {createSlice} from '@reduxjs/toolkit';
import {UserInterface} from "../../models/user";



interface AuthState {
    jwt: string | null;
    _id: string | null,
    userid: string | null,
    email: string | null,
    name: string | null,
    given_name: string | null,
    family_name: string | null,
    picture: string | null,
    savedJob: []
}

const initialState: AuthState = {
    jwt: null,
    _id: null,
    userid: null,
    email: null,
    name: null,
    given_name: null,
    family_name: null,
    picture: null,
    savedJob: []
};

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            return {
                ...state,
                jwt: action.payload
            }
        },
        logout: (state) => {
            return {
                ...state,
                jwt: null,
                _id: null,
                userid: null,
                email: null,
                name: null,
                given_name: null,
                family_name: null,
                picture: null,
                savedJob: []
            }
        },
        changeUserData: (state, action) => {
            return {
                ...state,
                _id: action.payload._id,
                userid: action.payload.userid,
                email: action.payload.email,
                name: action.payload.name,
                given_name: action.payload.given_name,
                family_name: action.payload.family_name,
                picture: action.payload.picture,
                savedJob: action.payload.savedJob || [],
            };
        },
        changeUserEmail: (state, action) => {
            return {
                ...state,
                email: action.payload.email,
            };
        },
        changeUserName: (state, action) => {
            return {
                ...state,
                name: action.payload.name
            };
        },
        changeUserGivenName: (state, action) => {
            return {
                ...state,
                given_name: action.payload.given_name
            };
        },
        changeUserFamilyName: (state, action) => {
            return {
                ...state,
                family_name: action.payload.family_name
            };
        },
        changeUserPicture: (state, action) => {
            return {
                ...state,
                picture: action.payload.picture,
            };
        },
        changeUserSavedJobs: (state, action) => {
            return {
                ...state,
                savedJob: action.payload.savedJob || [],
            };
        },
    }
});

export default authSlice.reducer;

export const {
    login,
    logout,
    changeUserData,
    changeUserName,
    changeUserEmail,
    changeUserPicture,
    changeUserFamilyName,
    changeUserGivenName,
    changeUserSavedJobs,
} = authSlice.actions;

