import {GoogleLogin} from "@react-oauth/google";
import React, {useState} from 'react';


import './login.styles.scss'
import {loginAPI, userDetailsAPI} from "../../../api/api";
import {LoginData} from "../../../models/login";
import {useDispatch} from "react-redux";
import {changeUserData, login} from "../../../redux/reducers/authReducer";

const Login = () => {
    const [isSigning, setIsSigning] = useState<boolean>(false)
    const dispatch = useDispatch();

    const loginToBackend = async (loginData: LoginData) => {
        try {
            setIsSigning(true)
            const response = await loginAPI.login(loginData)
            const {jwt} = response.data
            dispatch(login(jwt));
            getUserDetails();

        } catch (e) {
            console.log(e)
            setIsSigning(false)
        } finally {
            setIsSigning(false)
        }
    }
    const getUserDetails= ()=>{
        userDetailsAPI.getUserDetails().then((res)=>{
            console.log(res, "the user details have been retrieved")
            dispatch(changeUserData(res.data))
        }).catch((err)=>{
            console.log("error retrieving user details", err)
        })
    }

    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <div className="card-contain">
                        <div className="form-contain">
                            <h1>Login</h1>
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    const authData: LoginData = {loginData: {googleJwt: credentialResponse.credential}}
                                    loginToBackend(authData)
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ul className="bg-bubbles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </>
    )

}
export default Login;