import React, { useEffect, useState } from "react";
import "./App.scss";
import Login from "./components/pages/login/login.component";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import Layout from "./components/layout/layout.component";
import Search from "./components/pages/search/search.components";
import Job from "./components/pages/job/job.components";
import Profile from "./components/pages/profile/profile.components";
import LoaderWholePage from "./components/custom/Loaders/LoaderWholePage/LoaderWholePage.component";

function App() {
  const isAuthenticated = useSelector((state: RootState) => state.auth.jwt);
  const [jwt, setJwt] = useState<string | null>();

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("jwt", isAuthenticated);
      setJwt(isAuthenticated);
    } else {
      localStorage.removeItem("jwt");
      setJwt(null);
    }
  }, [isAuthenticated]);
  if (jwt) {
    return (
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
        <Route path="/" element={jwt ? <Layout /> : <Navigate to="/login" />}>
          <Route path="/search" element={<Search />} />
          <Route path="/job" element={<Job />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
      </Routes>
    );
  }
}

export default App;
