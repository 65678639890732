import './layout.styles.scss'
import NavBar from "../pages/navbar/navbar.components";
import React, {SetStateAction, useEffect, useState} from "react";
import {logout} from "../../redux/reducers/authReducer";
import {useDispatch} from "react-redux";
import Home from "../pages/home/home.component";
import {Route, Routes} from "react-router-dom";
import Search from "../pages/search/search.components";
import Job from "../pages/job/job.components";
import {PaginationParams, workDetailsParams} from "../../models/params";
import {workDetailsAPI, worksAPI} from "../../api/api";
import SideBar from "../pages/sidebar/sidebar.component";
import {Work} from "../../models/jobs";
import Profile from "../pages/profile/profile.components";

const Layout = () => {
    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(logout());
    }

    return(
        <div className="flex h-screen">
            <div className="w-fit" style={{background: "#101010"}}>
                {/*this is for the navbar*/}
                <NavBar logOut={logOut} />
            </div>
            <div className="flex-1 flex" style={{background: "#101010"}}>
                {/*other components will be here*/}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Home />
                        }
                    />
                    <Route path="search" element={<Search />} />
                    <Route path="job" element={<Job />} />
                    <Route path="profile" element={<Profile />} />
                </Routes>
            </div>
        </div>
    );
}

export default Layout;