import React, { useState } from 'react';

import './dropdowns.styles.scss'
import UpArrowSVG from "../icons/upArrowSVG";
import DownArrowSVG from "../icons/downArrowSVG";

interface DropdownsProps  {
    dropDownLabel: string;
    options: any
    handleOptionChange: (optionId: string) => void;
    selectedOptions: string ;
    isOpen: boolean;
    toggleDropdown: () => void;
    dropdownRef: any;
}

const Dropdown : React.FC<DropdownsProps> = ({dropDownLabel, options,handleOptionChange, selectedOptions, isOpen, toggleDropdown, dropdownRef}) => {

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button className="dropdown-toggle flex" onClick={toggleDropdown}>
                <div className="w-[90%]">
                    {selectedOptions !== "" ? selectedOptions :  dropDownLabel}
                </div>
                <div className="w-[10%] mt-1.5" >
                    { isOpen? <UpArrowSVG color="#000000" />: <DownArrowSVG color="#000000" />}
                </div>
            </button>
            {isOpen && (
                <div className="dropdown-menu p-2.5 flex flex-col fixed bg-zyWhite" >
                    <div className="overflow-y-auto h-72 w-fit">
                        {options.map((option: any) => (
                            <label key={option.id} className="text-zyBlack text-base font-normal mt-5 flex ml-2.5 mb-5">
                                <div className="check-style">
                                    <input
                                        type="checkbox"
                                        value={option.label}
                                        checked={selectedOptions === option.label}
                                        onChange={() => handleOptionChange(option.label)}
                                        className="custom-checkbox"
                                    />
                                </div>
                                <div className="check-label">
                                    {option.label}
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
