import './loading.styles.scss'
import React from "react";
const Loading =()=>{
    return(
        <>
            <span className="loader"></span>
        </>
    );

}
export default Loading;