import { ReactComponent as LinkedinIcon } from '../../../../assets/svgIcons/LinkedinIcon.svg';
import React from "react";

interface LinkedSVGProps {
    color: string;
    width?: number | string;
    height?: number | string;
}
const LinkedSVG : React.FC<LinkedSVGProps> = ({ color, width, height }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                <g clip-path="url(#clip0_92_2172)">
                    <path d="M17.8989 16.1383C17.926 16.4017 17.9621 16.6652 18.0072 17.0922C19.0815 15.6023 20.2821 14.4849 21.8529 13.749C23.6854 12.886 25.5992 12.9314 27.5039 13.0949C28.2984 13.1585 29.1198 13.3402 29.9233 13.6127C33.1731 14.6938 34.807 17.5464 35.2855 20.7442C36.1972 26.8673 35.5473 33.0177 35.773 39.1499C35.8091 40.1038 35.5021 40.4217 34.5543 40.3854C32.4961 40.3218 30.4378 40.3309 28.3886 40.3854C27.5942 40.4126 27.4408 40.1583 27.4498 39.4042C27.4859 34.8165 27.4949 30.2287 27.4498 25.65C27.4408 24.6961 27.2692 23.6877 26.9443 22.7883C26.1228 20.5262 24.3805 19.9993 22.2591 20.3354C19.4696 20.7806 18.3954 22.6429 18.3593 25.005C18.2871 29.2384 18.3051 33.4719 18.3232 37.7054C18.3232 38.3595 18.1697 39.0045 18.2961 39.6768C18.3954 40.2037 17.9621 40.3491 17.5017 40.3491C15.2358 40.3491 12.979 40.349 10.7132 40.3763C10.1896 40.3763 9.97292 40.24 10.009 39.6859C10.0542 38.8592 10.0181 38.0324 10.0181 37.2057C10.0181 29.6836 10.0271 22.1705 10 14.6484C10 13.8671 10.1715 13.5764 11.002 13.6036C12.8887 13.6763 14.7754 13.6672 16.6531 13.64C18.0885 13.6218 18.0524 13.6854 17.935 15.1026C17.0955 15.4478 17.4114 15.8021 17.935 16.1474L17.8989 16.1383Z" fill={color}/>
                    <path d="M0.0180547 26.3564C0.0180547 22.2501 0.0361092 18.1438 0 14.0466C0 13.2471 0.198601 12.9746 1.02911 13.0018C3.15053 13.0654 5.28097 13.0382 7.40239 13.0018C8.06138 12.9928 8.32318 13.2108 8.32318 13.8921C8.30512 21.3779 8.34123 28.8638 8.27804 36.3405C8.27804 37.2762 8.11555 38.2392 8.1336 39.1931C8.14263 39.6564 7.97111 39.7745 7.5378 39.7745C5.27195 39.7563 3.01512 39.7382 0.749267 39.7745C-0.0902731 39.7927 0.00902735 39.2839 0.00902735 38.7661C0.00902735 34.6235 0.00902735 30.4899 0.00902735 26.3473L0.0180547 26.3564Z" fill={color}/>
                    <path d="M4.65783 10.0644C2.32879 10.1098 0.0448782 7.92948 -0.000258318 5.35851C-0.0363675 2.86021 1.99478 0.489098 4.51339 0.525437C8.00696 0.579945 9.53258 2.66034 9.58674 5.17681C9.64091 7.71145 7.72712 10.028 4.65783 10.0553V10.0644Z" fill={color}/>
                    <path d="M37.5179 27.0447C36.9943 26.6995 36.6783 26.3543 37.5179 26V27.0447Z" fill={color}/>
                </g>
                <defs>
                    <clipPath id="clip0_92_2172">
                        <rect width="40" height="40" fill="white" transform="translate(0 0.525757)"/>
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}
export default LinkedSVG;
